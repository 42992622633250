<template>
  <div class="project_activities box is-shadowless py-5">

    <div v-if="loading">
      <loading-state />
    </div>

    <div v-else>
      <transition-group name="activity-list" tag="div" class="">
        <activity v-for="item in events" :key="item" :activity="item" class="" />
      </transition-group>
    </div>

  </div>
</template>

<script>
  import { defineAsyncComponent } from "vue";
  import { getProjectEventsStatApi } from '@/use/useProject'

  export default {
    name: 'Project Activity',
    components: {
      Activity: defineAsyncComponent(() => import('@/components/projects/activity')),
      LoadingState: defineAsyncComponent(() => import('@/components/general/Loading')),
    },
    props: {
      fullPath: {
        type: String,
        required: true
      },
    },

    setup(props) {

      const  {
        loading,
        events
      } = getProjectEventsStatApi(props.fullPath)

      return {
        loading,
        events
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
